const ImageCarouselStyles = `
  .image-carousel {
    position: relative;
    overflow: hidden;
  }

  .image-carousel__content {
    display: flex;
    overflow: auto;
  }

  .image-carousel__content::-webkit-scrollbar {
    display: none;
  }

  .image-carousel__content-item {
    display: block;
    width: 80%;
    margin: 0 8px;
    min-width: 80%;
  }

  .diesel-tracks .image-carousel__content-item {
    margin: 0 2px;
  }

  .image-carousel__image {
    max-width: 100%;
    width: 100%;
  }

  .image-carousel__nav {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
  }

  .image-carousel__nav-button {
    background-color: rgba(255, 255, 255, 0);
    bottom: 0;
    color: white;
    cursor: pointer;
    display: block;
    height: 100%;
    padding: 0;
    pointer-events: auto;
    position: absolute;
    text-align: center;
    top: 0;
    transition: background-color 0.2s linear, color 0.2s linear;
    width: 50px;
  }

  .image-carousel__nav-button .nts-icon {
    transition: fill 0.2s linear;
  }

  .image-carousel__nav-button:focus-within {
    outline: none;
  }

  button.image-carousel__nav-button--prev {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    left: 0;
    transform: rotate(180deg);
  }

  button.image-carousel__nav-button--next {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    right: 0;
  }

  .image-carousel__nav-button-box {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: center;
    transition: background-color 0.2s linear;
    width: 100%;
  }

  .diesel-tracks .image-carousel__nav-button-box {
    background-color: var(--nts-white);
    height: 56px;
  }

  .diesel-tracks .image-carousel__nav-button-box .nts-icon {
    fill: var(--nts-black);
  }

  @media(min-width: 1025px) {
    .image-carousel__content {
      overflow: hidden;
      padding: 0 50px;
    }

    .image-carousel__content-item {
      width: 72vw;
      margin: 0 21px;
      min-width: 72vw;
    }

    .image-carousel__nav {
      display: block;
    }

    .image-carousel__nav-button:hover .image-carousel__nav-button-box {
      background-color: rgba(255, 255, 255, 1);
    }

    .image-carousel__nav-button:hover .nts-icon {
      fill: var(--nts-black);
    }

    .image-carousel__nav-button:disabled {
      display: none;
    }

    .diesel-tracks .image-carousel__nav-button:hover .nts-icon {
      fill: var(--diesel-tracks-accent-color);
    }
  }
`

export default ImageCarouselStyles
