import React from 'react'
import PropTypes from 'prop-types'
import VideoBlockStyles from '../css/components/video-block'

export default function VideoBlock({ section }) {
  if (!section.video_id || !section.cover_image) return null

  return (
    <>
      <style>
        {VideoBlockStyles}
      </style>

      <div
        id={`VideoBlock${section.id}`}
        data-youtubeid={section.video_id}
        className="video-block video-item">
        <div className="video-block__wrapper">
          <div id="VideoBlockPlayer" className="video-block__player" />
          <div
            id="VideoBlockCover"
            className="video-block__cover"
            style={{ backgroundImage: `url(${section.cover_image.formats.large.url})` }}>
            <div id="VideoBlockLoading" className="video-block__loading">
              <svg
                width="200"
                height="200"
                viewBox="0 0 200 200"
                color="#fff"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient id="spinner-secondHalf">
                    <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
                    <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
                  </linearGradient>
                  <linearGradient id="spinner-firstHalf">
                    <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
                    <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
                  </linearGradient>
                </defs>

                <g strokeWidth="8">
                  <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
                  <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    d="M 4 100 A 96 96 0 0 1 4 98"
                  />
                </g>

                <animateTransform
                  from="0 0 0"
                  to="360 0 0"
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1300ms"
                />
              </svg>
            </div>
            <button
              id="VideoBlockPlayButton"
              className="video-block__play-button visuallyhidden"
              aria-label="Video Play Button">
              <div className="video-block__play-icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 600 600">
                  <g>
                    <g transform="translate(-772 -385)">
                      <g transform="translate(0 43)">
                        <g transform="translate(18)">
                          <path d="M1260.4 651.3 882.8 861.2c-4.4 2.4-8.2 2.7-11.2 1-3.1-1.7-4.6-5.1-4.6-10.2V433.2c0-4.8 1.5-8.2 4.6-10.2 3.1-2 6.8-1.7 11.2 1l377.6 210c4.4 2.4 6.6 5.3 6.6 8.7 0 3.3-2.2 6.2-6.6 8.6z"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

VideoBlock.propTypes = {
  section: PropTypes.object,
}
