import React from 'react'
import PropTypes from 'prop-types'

export default function ImageCarousel({ section }) {
  return (
    <div
      id={`ImageCarousel${section.id}`}
      className="image-carousel">
      <div id="ImageCarouselContent" className="image-carousel__content">
        {section.images.map((image, i) => {
          const imageSrc = image.formats.large
            ? image.formats.large.url
            : image.formats.medium
              ? image.formats.medium.url
              : image.formats.small.url
          return (
            <div className="image-carousel__content-item" key={i}>
              <img
                src={imageSrc}
                className="image-carousel__image"
                alt={image.caption} />
            </div>
          )
        })}
      </div>

      <div className="image-carousel__nav">
        <button
          id="ImageCarouselNavButtonPrev"
          className="image-carousel__nav-button image-carousel__nav-button--prev"
          aria-label="Carousel Previous Button">
          <div className="image-carousel__nav-button-box">
            <svg
              className="nts-icon nts-icon--inverted nts-icon--scroll-button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 600 600">
              <path d="M150 117l60-61 240 244-240 244-60-61 180-183-180-183z" />
            </svg>
          </div>
        </button>

        <button
          id="ImageCarouselNavButtonNext"
          className="image-carousel__nav-button image-carousel__nav-button--next"
          aria-label="Carousel Next Button">
          <div className="image-carousel__nav-button-box">
            <svg
              className="nts-icon nts-icon--inverted nts-icon--scroll-button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 600 600">
              <path d="M150 117l60-61 240 244-240 244-60-61 180-183-180-183z" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  )
}

ImageCarousel.propTypes = {
  section: PropTypes.object,
}
