const VideoBlockStyles = `
  .video-block {
    position: relative;
  }

  .video-block__wrapper {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }

  .video-block__player {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  .video-block__cover {
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .video-block__play-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: 1;
  }

  .video-block__play-button:focus .video-block__play-icon-container,
  .video-block__play-button:hover .video-block__play-icon-container {
    opacity: 0.7;
  }

  .video-block__play-icon-container {
    align-items: center;
    background-color: var(--nts-white);
    display: flex;
    height: 60px;
    justify-content: center;
    transition: opacity linear 0.2s;
    width: 60px;
  }

  .video-block__play-icon-container svg {
    width: 48px;
  }

  .video-block__loading {
    background-color: var(--nts-black);
    content: '';
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity linear 0.2s;
    z-index: 1;
  }

  .video-block__loading svg {
    animation: rotating 1s linear infinite;
    width: 48px
  }

  @keyframes rotating {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media(min-width: 1025px) {
    .video-block {
      align-items: center;
      display: flex;
      height: 80vh;
    }

    .video-block__wrapper {
      height: 100%;
      margin: 0 auto;
      max-height: 700px;
      max-width: 1100px;
      padding: 0;
      position: initial;
      width: 100%;
    }

    .video-block__player {
      position: relative;
    }
  }
`

export default VideoBlockStyles
