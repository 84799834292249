import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import AMillionWaysHero from '../components/a-million-ways-hero'
import AMillionWaysCtaBlock from '../components/a-million-ways-cta-block'
import AMillionWaysStyles from '../css/pages/a-million-ways-styles'
import ImageCarouselStyles from '../css/components/image-carousel'
import ImageCarousel from '../components/image-carousel'
import VideoBlock from '../components/video-block'

const AMillionWaysCollectionTemplate = ({ data }) => {
  const article = data.strapiAMillionWaysCollection
  const ConditionalWrapper = ({ condition, wrapper, children }) => {
    return condition ? wrapper(children) : children
  }

  function renderHero(section) {
    return (
      <AMillionWaysHero
        section={section}
        key={section.strapi_component} />
    )
  }

  function renderLogoBlock(section) {
    return (
      <section className="a-million-ways__section a-million-ways__logo-block" key={section.strapi_component}>
        <div className="a-million-ways__section-wrapper">
          {section.logo &&
            <ConditionalWrapper
              condition={section.link_url}
              wrapper={children => <a href={section.link_url} className="nts-app">{children}</a>}>
              <img
                className="a-million-ways__logo-block-image"
                src={section.logo.url}
                alt={section.name}
                style={{ maxWidth: `${section.logo_width}px` }} />
            </ConditionalWrapper>
          }
        </div>
      </section>
    )
  }

  function renderAbout(section) {
    return (
      <section
        className="a-million-ways__section a-million-ways__about"
        key={section.strapi_component}>
        <div className="a-million-ways__section-wrapper">
          <div className="a-million-ways__about-paragraph-container">
            {section.subheading &&
              <span className="a-million-ways__talents-subheading">
                {section.subheading}
              </span>
            }

            {section.heading &&
              <h2 className="a-million-ways__heading">
                {section.heading}
              </h2>
            }

            {section.body &&
              <div
                className="a-million-ways__paragraph a-million-ways__paragraph--small a-million-ways__talents-description"
                dangerouslySetInnerHTML={{ __html: section.body.replace(/\n/g, '<br />') }} />
            }
          </div>
        </div>
      </section>
    )
  }

  function renderImageCarousel(section) {
    if (!section.images.length) return

    return (
      <section
        className="a-million-ways__section"
        key={`${section.strapi_component}-${section.id}`}>
        <ImageCarousel section={section} />
      </section>
    )
  }

  function renderVideoBlock(section) {
    return (
      <section
        className="a-million-ways__section"
        key={`${section.strapi_component}-${section.id}`}>
        <VideoBlock section={section} />
      </section>
    )
  }

  function renderTalents(section) {
    return (
      <section
        className="a-million-ways__section a-million-ways__section--talents"
        key={section.strapi_component}>
        <div className="a-million-ways__section-wrapper">
          {section.heading &&
            <header className="a-million-ways__talents-header">
              <h2 className="a-million-ways__heading">
                {section.heading}
              </h2>
            </header>
          }

          {!!section.artists.length &&
            <div className="a-million-ways__collection-artists">
              {section.artists.map((artist, index) => {
                return (
                  <div
                    id="AMillionWaysArtist"
                    className="a-million-ways__collection-artist"
                    key={index}>
                    <div className="a-million-ways__collection-artist-media-container video-item">
                      <div id="VideoBlockCover">
                        {artist.image &&
                          <img
                            className="a-million-ways__artist-image"
                            src={artist.image.formats.medium.url}
                            loading="lazy"
                            alt={artist.name} />
                        }

                        {artist.youtube_id &&
                          <>
                            <div
                              id="VideoBlockLoading"
                              className="a-million-ways__collection-artist-media-loading">
                              <svg
                                width="200"
                                height="200"
                                viewBox="0 0 200 200"
                                color="#fff"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <defs>
                                  <linearGradient id="spinner-secondHalf">
                                    <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
                                    <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
                                  </linearGradient>
                                  <linearGradient id="spinner-firstHalf">
                                    <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
                                    <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
                                  </linearGradient>
                                </defs>

                                <g strokeWidth="8">
                                  <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
                                  <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    d="M 4 100 A 96 96 0 0 1 4 98"
                                  />
                                </g>

                                <animateTransform
                                  from="0 0 0"
                                  to="360 0 0"
                                  attributeName="transform"
                                  type="rotate"
                                  repeatCount="indefinite"
                                  dur="1300ms"
                                />
                              </svg>
                            </div>
                            <button
                              id="VideoBlockPlayButton"
                              className="a-million-ways__collection-artist-media-play-button visuallyhidden"
                              aria-label="Video Play Button">
                              <span className="a-million-ways__collection-artist-media-play-button-label">
                                Watch Video
                              </span>
                              <div className="a-million-ways__collection-artist-media-play-icon-container">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" version="1.1" viewBox="0 0 600 600">
                                  <g>
                                    <g transform="translate(-772 -385)">
                                      <g transform="translate(0 43)">
                                        <g transform="translate(18)">
                                          <path d="M1260.4 651.3 882.8 861.2c-4.4 2.4-8.2 2.7-11.2 1-3.1-1.7-4.6-5.1-4.6-10.2V433.2c0-4.8 1.5-8.2 4.6-10.2 3.1-2 6.8-1.7 11.2 1l377.6 210c4.4 2.4 6.6 5.3 6.6 8.7 0 3.3-2.2 6.2-6.6 8.6z"/>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </button>
                          </>
                        }
                      </div>

                      {artist.youtube_id &&
                        <div
                          id={`AMillionWaysArtistVideoPlayer${index}`}
                          className="a-million-ways__collection-artist-video-player"
                          data-youtubeid={artist.youtube_id} />
                      }
                    </div>
                    <div className="a-million-ways__collection-artist-details">
                      <div className="a-million-ways__collection-artist-details-wrapper">
                        <h3 className="a-million-ways__collection-artist-name">
                          <span className="visuallyhidden">
                            {artist.name}
                          </span>
                          <img
                            className="a-million-ways__artist-name-image"
                            src={artist.name_image.url}
                            alt={artist.name} />
                        </h3>

                        {artist.description &&
                          <div
                            className="a-million-ways__paragraph a-million-ways__paragraph--small a-million-ways__artist-description"
                            dangerouslySetInnerHTML={{ __html: artist.description.replace(/\n/g, '<br />') }} />
                        }

                        {(artist.instagram_link || artist.soundcloud_link) &&
                          <div className="a-million-ways__artist-social-links-group">
                            {artist.instagram_link &&
                              <a
                                href={artist.instagram_link}
                                className="a-million-ways__artist-social-link a-million-ways__artist-social-link--instagram"
                                target="_blank"
                                rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                              </a>
                            }

                            {artist.soundcloud_link &&
                              <a
                                href={artist.soundcloud_link}
                                className="a-million-ways__artist-social-link a-million-ways__artist-social-link--soundcloud"
                                target="_blank"
                                rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7 17.939h-1v-8.068c.308-.231.639-.429 1-.566v8.634zm3 0h1v-9.224c-.229.265-.443.548-.621.857l-.379-.184v8.551zm-2 0h1v-8.848c-.508-.079-.623-.05-1-.01v8.858zm-4 0h1v-7.02c-.312.458-.555.971-.692 1.535l-.308-.182v5.667zm-3-5.25c-.606.547-1 1.354-1 2.268 0 .914.394 1.721 1 2.268v-4.536zm18.879-.671c-.204-2.837-2.404-5.079-5.117-5.079-1.022 0-1.964.328-2.762.877v10.123h9.089c1.607 0 2.911-1.393 2.911-3.106 0-2.233-2.168-3.772-4.121-2.815zm-16.879-.027c-.302-.024-.526-.03-1 .122v5.689c.446.143.636.138 1 .138v-5.949z"/></svg>
                              </a>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          }

          {section.cta &&
            <div className="a-million-ways__button-group">
              <a
                href={section.cta.link_url}
                className="nts-app a-million-ways__button">
                {section.cta.label}
              </a>
            </div>
          }
        </div>
      </section>
    )
  }

  function renderAMillionWaysCtaBlock(section) {
    return (
      <AMillionWaysCtaBlock
        section={section}
        key={section.strapi_component} />
    )
  }

  function renderEventsBlock(section) {
    return (
      <section
        className="a-million-ways__section a-million-ways__events-block"
        key={section.strapi_component}>
        <div className="a-million-ways__section-wrapper">
          <header className="a-million-ways__talents-header">
            {section.subheading &&
              <span className="a-million-ways__talents-subheading">
                {section.subheading}
              </span>
            }

            {section.heading &&
              <h2 className="a-million-ways__heading">
                {section.heading}
              </h2>
            }

            {section.description &&
              <div
                className="a-million-ways__paragraph a-million-ways__paragraph--small a-million-ways__talents-description"
                dangerouslySetInnerHTML={{ __html: section.description.replace(/\n/g, '<br />') }} />
            }
          </header>

          {!!section.events.length &&
            <div className="a-million-ways__collection-events">
              {section.events.map((event, index) => {
                return (
                  <div
                    className="a-million-ways__collection-event"
                    key={index}>
                    <div className="a-million-ways__collection-event-image-container">
                      {event.image &&
                        <ConditionalWrapper
                          condition={event.cta_link}
                          wrapper={children => <a href={event.cta_link} className="nts-app">{children}</a>}>
                          <img
                            className="a-million-ways__event-image"
                            src={event.image.formats.large.url}
                            loading="lazy"
                            alt={event.name} />
                        </ConditionalWrapper>
                      }
                    </div>
                    <div className="a-million-ways__collection-event-details">
                      <div className="a-million-ways__collection-event-details-wrapper">
                        <h3 className="a-million-ways__collection-event-heading">
                          {event.heading}
                        </h3>

                        {(event.cta_link && event.cta_label) &&
                          <div className="a-million-ways__button-group a-million-ways__button-group--cta-block">
                            <a
                              href={event.cta_link}
                              className="nts-app a-million-ways__button">
                              {event.cta_label}
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          }

          {section.cta &&
            <div className="a-million-ways__button-group">
              <a
                href={section.cta.link_url}
                className="nts-app a-million-ways__button">
                {section.cta.label}
              </a>
            </div>
          }
        </div>
      </section>
    )
  }

  function renderCustomLink({ href, children }) {
    const isExternalLink = href.includes('http')
    return (
      /* eslint-disable react/jsx-no-target-blank */
      <a
        href={href}
        className={`${!isExternalLink ? 'nts-app' : ''}`}
        target={isExternalLink ? '_blank' : '_self'}
        rel={isExternalLink ? 'noopener noreferrer': ''}>
        {children}
      </a>
      /* eslint-enable react/jsx-no-target-blank */
    )
  }

  function renderCtaBlock(section) {
    return (
      <section
        className="a-million-ways__section a-million-ways__section--cta-block"
        key={`${section.strapi_component}-${section.id}`}>
        {section.body &&
          <div className="a-million-ways__paragraph a-million-ways__paragraph--small">
            <ReactMarkdown components={{ a: renderCustomLink }}>
              {section.body}
            </ReactMarkdown>
          </div>
        }

        {section.cta &&
          <>
            <div className="a-million-ways__button-group">
              {section.cta.link_url && section.cta.label &&
                <a
                  href={section.cta.link_url}
                  className="a-million-ways__button"
                  target="_blank"
                  rel="noreferrer">
                  {section.cta.label}
                </a>
              }

              {section.cta.note &&
                <span className="a-million-ways__cta-note">
                  {section.cta.note}
                </span>
              }
            </div>
          </>
        }
      </section>
    )
  }

  function renderFooter(section) {
    return (
      <section
        className="a-million-ways__footer a-million-ways__section a-million-ways__section--footer"
        style={{ backgroundImage: `url(${section.bg_image.formats.medium.url})` }}
        key={section.strapi_component}>
        <div className="a-million-ways__section-wrapper">
          {section.heading &&
            <h2 className="a-million-ways__heading">
              {section.heading}
            </h2>
          }

          {section.body &&
            <div
              className="a-million-ways__paragraph a-million-ways__paragraph--small a-million-ways__talents-description"
              dangerouslySetInnerHTML={{ __html: section.body.replace(/\n/g, '<br />') }} />
          }

          {section.cta &&
            <div className="a-million-ways__button-group">
              <a
                href={section.cta.link_url}
                className="nts-app a-million-ways__button a-million-ways__button--alt">
                {section.cta.label}
              </a>
            </div>
          }
        </div>
      </section>
    )
  }

  function renderTermsAndConditions(section) {
    return (
      <section
        className="a-million-ways__section a-million-ways__terms-and-conditions"
        key={section.strapi_component}>
        <div className="a-million-ways__section-wrapper a-million-ways__section-wrapper--terms-and-conditions">
          {section.body &&
            <div className="a-million-ways__paragraph a-million-ways__paragraph--small">
              <ReactMarkdown components={{ a: renderCustomLink }}>
                {section.body}
              </ReactMarkdown>
            </div>
          }
        </div>
      </section>
    )
  }

  return (
    <Layout>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link
        href="https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@400;700&display=swap"
        rel="stylesheet" />

      <style>
        {AMillionWaysStyles}
        {ImageCarouselStyles}
      </style>

      <main className="a-million-ways a-million-ways--collection">
        {article.sections.map((section) => {
          switch(section.strapi_component) {
          case 'a-million-ways.a-million-ways-hero':
            return renderHero(section)
          case 'a-million-ways.a-million-ways-logo-block':
            return renderLogoBlock(section)
          case 'a-million-ways.a-million-ways-about':
            return renderAbout(section)
          case 'a-million-ways.artists':
            return renderTalents(section)
          case 'a-million-ways.a-million-ways-cta-block':
            return renderAMillionWaysCtaBlock(section)
          case 'a-million-ways.events-block':
            return renderEventsBlock(section)
          case 'a-million-ways.a-million-ways-footer':
            return renderFooter(section)
          case 'components.image-carousel':
            return renderImageCarousel(section)
          case 'components.video-block':
            return renderVideoBlock(section)
          case 'components.cta-block':
            return renderCtaBlock(section)
          case 'components.terms-and-conditions':
            return renderTermsAndConditions(section)
          default:
            return null
          }
        })}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiAMillionWaysCollection(slug: { eq: $slug }) {
      strapiId
      sections
    }
  }
`

AMillionWaysCollectionTemplate.propTypes = {
  data: PropTypes.object,
}

export default AMillionWaysCollectionTemplate
